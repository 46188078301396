// extracted by mini-css-extract-plugin
export var gamblingDiv = "gambling-module--gamblingDiv--gqiAL";
export var featureHeader = "gambling-module--featureHeader--3STWG";
export var aboutSectionContent = "gambling-module--aboutSectionContent--21mL3";
export var gamblingSub1 = "gambling-module--gamblingSub1--3QeeR";
export var gamblingCol1 = "gambling-module--gamblingCol1--21lIi";
export var gamblingMap = "gambling-module--gamblingMap--1K6Q1";
export var aboutSection2 = "gambling-module--aboutSection2--3dwwO";
export var taxRevenue = "gambling-module--taxRevenue--2FgZ8";
export var smallRedMain = "gambling-module--smallRedMain--2ly0O";
export var taxDiv = "gambling-module--taxDiv--ON78s";
export var smallredDiv = "gambling-module--smallredDiv--2pfK-";
export var mainPointDiv = "gambling-module--mainPointDiv--L7dzx";
export var mailPointImg = "gambling-module--mailPointImg--1cILi";
export var mainPointText = "gambling-module--mainPointText--1WJi7";
export var mainPointDesc = "gambling-module--mainPointDesc--3P6i9";
export var section3 = "gambling-module--section3--1_zKW";
export var section3desc = "gambling-module--section3desc--34kNZ";
export var section3Img = "gambling-module--section3Img--2FoET";
export var s2ction3imgDes = "gambling-module--s2ction3imgDes--1SUZq";
export var oneTimeHeader = "gambling-module--oneTimeHeader--3obXN";
export var legistlature = "gambling-module--legistlature--4IYU6";
export var legistlatureText = "gambling-module--legistlatureText--1Pk3y";
export var legislatureImg = "gambling-module--legislatureImg--gvCTh";
export var legistlatureTextSub = "gambling-module--legistlatureTextSub--1g_6_";
export var legislatureContentDiv = "gambling-module--legislatureContentDiv--vIF2F";
export var legislature1 = "gambling-module--legislature1--1f_eZ";
export var legislature2 = "gambling-module--legislature2--3C2sU";
export var whatDivCol = "gambling-module--whatDivCol--3jwVr";