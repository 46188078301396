import React from "react";
import Layout from '../components/layout/layout'
import * as styles from '../components/gambling.module.css';

import GamblingMap from '../images/gamblingmap.png';
import mainPoint from '../images/mainPoint.svg';
import Section3Img from '../images/section3img.png';
import legislatureImg from '../images/legislature.png';
import legislature1 from '../images/legislature1.png';

class GamblingStats extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  render(){
    return(
      <Layout>
        <div className={styles.gamblingDiv}>
          <text className={styles.featureHeader}>How does WV compare to our peers?</text>
          <div className={styles.gamblingSub1}>
            <text className={styles.aboutSectionContent}>West Virginia was the fifth state to legalize sports betting in the United States. Although we had the first mover advantage, our state has not capitalized on this tremendous opportunity. West Virginia is losing MILLIONS of tax dollars because of the current legislation.</text>
          </div>
          <div className="row">
            <div className="col-lg-6" id={styles.whatDivCol}>
              <div className={styles.gamblingCol1}>
                <text className={styles.aboutSectionContent} numberOfLines={5}>Sports betting has been illegal in the United States, except Nevada, until the Supreme Court lifted the federal ban on sports betting May 14, 2018. Several states decided to move quickly on this ruling and legalized sports betting in 2018. These states, in the order of legalization, include New Jersey, Delaware, Mississippi, West Virginia, New Mexico, Pennsylvania, and Rhode Island. Since this Supreme Court Ruling, over 20 states have legalized sports betting.</text>
              </div>
            </div>
            <div className="col-lg-6" id={styles.whatDivCol}>
              <img className={styles.gamblingMap} src={GamblingMap} alt="landingimg" />
            </div>
          </div>
          <text className={styles.aboutSection2}>Hints:</text>
          <ul>
            <li>Darker Blue = More State Tax Revenue</li>
            <li>Some states have been around longer than others</li>
            <li>See the following visual for total dollar amount of state tax revenue for each state</li>
          </ul>
        </div>
        <div className={styles.taxRevenue}>
          <div className={styles.taxDiv}>
            <div className={styles.mainPointDiv}>
              <img className={styles.mailPointImg} src={mainPoint} alt="landingimg" />
              <text className={styles.mainPointText}>Main Point</text>
            </div>
            <text className={styles.mainPointDesc}>West Virginia was the 5th state to legalize sports betting, but currently sits in 12th place in regards to Total State Tax Revenue.</text>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6" id={styles.whatDivCol}>
            <div className={styles.section3}>
              <img className={styles.section3Img} src={Section3Img} alt="landingimg" />
              <text className={styles.s2ction3imgDes}>*States listed N/A had discrepancies in their reported data*</text>
            </div>
          </div>
          <div className="col-lg-6" id={styles.whatDivCol}>
            <div className={styles.section3desc}>
              <text className={styles.aboutSectionContent}><p>WV has completely lost our first mover advantage and needs to act fast before our state gets left behind, like usual. Population of individuals over the legal gambling age in these states does play a significant role in these numbers. However, WV has a major legislation issue that has hindered potential economic growth for our beloved state.</p> <p>Below we will focus specifically on West Virginia data and how we think WV can recapture the millions of dollars in tax revenue lost.</p></text>
            </div>
          </div>
        </div>
        <div className={styles.smallRedMain}>
          <div className={styles.smallredDiv}>
            <text className={styles.mainPointDesc}>West Virginia is Lagging Behind</text>
          </div>
        </div>
        <div className={styles.legistlature}>
          <text className={styles.oneTimeHeader}>In 2018, Legislature passed the West Virginia Sports Wagering Act.</text>
          <div className={styles.legistlatureText}>
            <div className={styles.legistlatureTextSub}>
              <text className={styles.aboutSectionContent}>The Act gave the state’s existing brick-and-mortar casinos exclusive control of online sports wagering. Each casino was given three skins that they could assign to online sports wagering companies in exchange for part of those companies’ West Virginia revenues. The casinos have made lucrative deals with several large out-of-state companies.</text>
            </div>
            <div className={styles.legislature1}>
              <img className={styles.legislatureImg} src={legislatureImg} alt="landingimg" />
            </div>
            <div className={styles.legislatureContentDiv}>
              <text className={styles.aboutSectionContent}>If we take the total state tax of $6,944,341 and divide it by the total number of skins (7), then we get a value of $410,502.92 a skin per year.</text>
            </div>
            <div className={styles.legislature1}>
              <img className={styles.legislatureImg} src={legislature1} alt="landingimg" />
            </div>
            <div className={styles.legislatureContentDiv}>
              <text className={styles.aboutSectionContent}><p>The chart above displays the per year value of skin tied to each owner. When compared to the state average of $410,502, we can see the value of missed opportunities from these owners.</p> <p>**This missed opportunity is directly tied to the current teathered licensing structure in WV.**</p> <p>Delaware North has 2 skins with Miomni that are inactive. Delaware North's average value of skin per year is already significantly behind the WV average. When we factor in their inactive licenses, they are costing WV hundreds of thousands of dollars.</p></text>
            </div>
            <text className={styles.oneTimeHeader}>If we were to compare these two inactive licenses to the WV average value, Delaware North has cost the state over $2,000,000 in lost tax revenue since sports betting was legalized in WV!</text>
            <div className={styles.legislature2}>
              <text className={styles.aboutSectionContent}>**Why shouldn't we change the licensing structure in WV to promote more active licenses and fair market competition? The sports betting industry in WV is currently an oligopoly.**</text>
            </div>
          </div>
        </div>
        <div className={styles.taxRevenue}>
          <div className={styles.taxDiv}>
            <div className={styles.mainPointDiv}>
              <img className={styles.mailPointImg} src={mainPoint} alt="landingimg" />
              <text className={styles.mainPointText}>Main Point</text>
            </div>
            <text className={styles.mainPointDesc}>The tax value of a skin is roughly $410,502.92 per year in WV. Delaware North currently has two active skins with the average value of a skin per year at $48,155.</text>
          </div>
        </div>
        <div className={styles.legistlature}>
          <text className={styles.oneTimeHeader}>How Can West Virginia Catch up?</text>
          <div className={styles.legistlatureText}>
            <div className={styles.legistlatureTextSub}>
              <text className={styles.aboutSectionContent}>All PropMe asks, is the chance for West Virginia-based companies to compete on a level playing field in sports wagering. If the existing law makes that impossible, then the law must change to include untethered licenses. The existing law allows each casino three skins that they can assign to online sports wagering companies, in exchange for part of those companies’ West Virginia revenues. This is called tethered licenses. The casinos have made lucrative deals with several large out-of-state companies. PropMe advocates for fair market regulation in the West Virginia Sports Betting Industry. This will drive competition in our state, which will drive economic growth in WV through increased tax revenues.</text>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default GamblingStats